<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
            <b-table-simple
              striped
              stacked="md"
              class="mt-2 responsive text-center"
              id="tableAccept"
              :item="fetchCategories"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <b-thead>
                <b-tr class="text-center">
                  <b-th>Icone</b-th>
                  <b-th>Categoria</b-th>
                  <b-th>SubCategorias</b-th>
                  <b-th>Ação</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr :key="indextr" v-for="(tr, indextr) in categories">
                  <b-td>
                    <b-avatar
                      size="40"
                      variant="light-primary"
                      v-if="!tr.icon"
                      :src="require('@/assets/images/icons/unknown.png')"
                    />
                    <b-avatar
                      v-else
                      size="40"
                      variant="light-primary"
                      :src="tr.icon"
                    />
                  </b-td>
                  <b-td>{{ tr.name }}</b-td>
                  <b-td>
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                      @click="fetchDetails(tr.id)"
                      v-b-modal.modal-view
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-success"
                      class="btn-icon rounded-circle"
                      v-b-modal.modal-store
                      @click="showDetail(tr.id)"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                  </b-td>
                  <b-td>
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-warning"
                      class="btn-icon rounded-circle"
                      v-b-modal.modal-update-category
                      @click="showDetail(tr.id)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-danger"
                      class="btn-icon rounded-circle"
                      v-b-modal.modal-delete-category
                      @click="showDetail(tr.id)"
                    >
                      <feather-icon icon="Trash2Icon" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-pagination
              @change="handlePageChange"
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalRows"
              aria-controls="tableAccept"
              class="mt-1"
            />
          </b-overlay>
        </b-card>
      </b-col>
      <b-col lg="4" sm="6">
        <b-card>
          <h3 class="text-center">Nome da categoria</h3>
          <b-form-input id="name" placeholder="Por favor insira o nome da categoria" v-model="dataCategory.name"/>
          <h3 class="text-center mt-1">Escolha a cor de fundo</h3>
          <b-form-input id="name" type="color" placeholder="Por favor insira uma cor" v-model="dataCategory.color"/>
          <b-form-input class="hidden" name="_token" :value="csrf" />
          <h3 class="text-center mt-1">Insira um icone</h3>
          <b-form-file label="Icone" type="file" @change="update_image" v-model="icon" accept="image/*" name="url"/>
          <b-button variant="gradient-primary" class="mt-1" block @click="storeCategory">
            Cadastrar
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <!-- Store SubCategory -->
    <b-modal
      id="modal-store"
      modal-class="modal-success"
      ref="modal-store"
      centered
      title="Adicionar SubCategoria"
    >
      <b-card-text>
        <hr>
        <h3 class="text-center">Nome da SubCategoria</h3>
          <b-form-input id="name" placeholder="Por favor insira o nome da SubCategoria" v-model="dataSubCategory.name"/> 
        <hr>
      </b-card-text>
      <template #modal-footer>
        <b-button variant="gradient-primary" block @click="storeSubCategory" :value="dataSubCategory.event_type_id = categoriesModel.id">
          Cadastrar
        </b-button>
      </template>
    </b-modal>
    <!-- Modal de delete Category -->
    <b-modal
      id="modal-delete-category"
      ok-variant="danger"
      modal-class="modal-danger"
      ref="modal-delete-category"
      centered
      title="Deletar Categoria"
    >
      <b-card-text>
        <p>Você está preste a deletar esta categoria do sistema:</p>  
        <i style="font-size: 12px"><b>Obs:</b> Ao deletar esta categorias, todas as subcategorias que pertencem a mesma serão deletadas automaticamente.</i>
        <hr>
        <p> <b>Categoria: </b>{{categoriesModel ? categoriesModel.name : ''}} </p>
        <hr>
        Por favor, confirme a exclusão antes de prosseguir.
        <b-form-checkbox
          id="checkbox-1"
          @change="newDeleteStatus"
          v-model="DeleteStatus"
          name="checkbox-1"
          value="accepted"
          unchecked-value="null"
        >
          Concordo em excluir esta categoria
        </b-form-checkbox>
      </b-card-text>
      <template #modal-footer>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-2"
            variant="outline-danger"
            block
            :disabled="DeleteStatus == null"
            @click="deleteCategory(categoriesModel.id)"
          >
            Deletar
          </b-button>
        </b-col>
      </template>
    </b-modal>
    <!-- Update Category -->
    <b-modal
      id="modal-update-category"
      modal-class="modal-warning"
      ref="modal-update-category"
      centered
      title="Editar Categoria"
    >
      <b-card-text v-if="categoriesModel">
        <hr>
        <h3 class="text-center">Nome da Categoria</h3>
          <b-form-input id="name" @change="newNameCategory" placeholder="Por favor insira o nome da Categoria" :value="updateCategories.name = categoriesModel.name">
            {{categoriesModel.name}}
          </b-form-input> 
          <h3 class="text-center mt-1">Escolha a cor de fundo</h3>
          <b-form-input id="name" type="color" @change="newColorCategory" placeholder="Por favor insira uma cor" :value="updateCategories.color = categoriesModel.color">
            {{categoriesModel.color}}
          </b-form-input>
           <b-form-input class="hidden" name="_token" :value="csrf" />
          <h3 class="text-center mt-1">Insira um icone</h3>
          <b-form-file label="Icone" type="file" @change="update_image_update" v-model="icon" accept="image/*" name="url"/>
        <hr>
      </b-card-text>
      <template #modal-footer>
        <b-button variant="gradient-primary" block @click="updateCategory" :value="updateCategories.id = categoriesModel.id">
          Atualizar
        </b-button>
      </template>
    </b-modal>
    <!-- View SubCategory -->
    <b-modal
      id="modal-view"
      modal-class="modal-primary"
      ref="modal-view"
      centered
      title="SubCategorias"
    >
      <b-card-text >
        <b-table-simple
          striped
          stacked="md"
          class="mt-2 responsive text-center"
          :item="fetchDetails"
        >
          <b-thead>
            <b-tr class="text-center">
              <b-th>#</b-th>
              <b-th>SubCategorias</b-th>
              <b-th>Ação</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr :key="indextr" v-for="(tr, indextr) in categoryDetails">
              <b-td>{{ tr.id }}</b-td>
              <b-td>{{ tr.name }}</b-td>
              <b-td>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-warning"
                  class="btn-icon rounded-circle"
                  v-b-modal.modal-update-subcategory
                  @click="updateSubCategories.id = tr.id"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-danger"
                  class="btn-icon rounded-circle"
                  v-b-modal.modal-delete-subcategory
                  @click="id_delete= tr.id"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-td>
              
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card-text>
      <template #modal-footer>
        -
      </template>
    </b-modal>
    <!-- Modal de delete SubCategory -->
    <b-modal
      id="modal-delete-subcategory"
      ok-variant="danger"
      modal-class="modal-danger"
      ref="modal-delete-subcategory"
      centered
      title="Deletar SubCategoria"
    >
      <b-card-text>
        <p>Você está preste a deletar esta SubCategoria do sistema</p>  
        <hr>
        Por favor, confirme a exclusão antes de prosseguir.
        <b-form-checkbox
          id="checkbox-1"
          @change="newDeleteStatus"
          v-model="DeleteStatus"
          name="checkbox-1"
          value="accepted"
          unchecked-value="null"
        >
          Concordo em excluir esta SubCategoria
        </b-form-checkbox>
      </b-card-text>
      <template #modal-footer>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-2"
            variant="outline-danger"
            block
            :disabled="DeleteStatus == null"
            @click="deleteSubCategory()"
          >
            Deletar
          </b-button>
        </b-col>
      </template>
    </b-modal>
    <!-- Update Category -->
    <b-modal
      id="modal-update-subcategory"
      modal-class="modal-warning"
      ref="modal-update-subcategory"
      centered
      title="Editar SubCategoria"
    >
      <b-card-text>
        <hr>
        <h3 class="text-center">Nome da SubCategoria</h3>
          <b-form-input id="name" placeholder="Por favor insira o nome da Categoria" v-model="updateSubCategories.name">
          </b-form-input>
        <hr>
      </b-card-text>
      <template #modal-footer>
        <b-button variant="gradient-primary" block @click="updateSubCategory">
          Atualizar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showSearch: true,
      isLoading: true,
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,
      icon: null,

      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',

      id_delete: null,

      DeleteStatus: null,
      categoriesModel: null,

      dataCategory: {
        name: null,
        color: null,
        icon: null
      },

      updateCategories: {
        id: null,
        name: 'Categoria',
        color: 'cor',
        icon: null
      },

      dataSubCategory: {
        name: null,
        event_type_id: null
      },

      updateSubCategories: {
        id: null,
        name: null
      }

    };
  },
  computed: {
    ...mapState("categories", ["categories", "categoryDetails","pagination"]),
  },
  methods: {
    ...mapActions("categories", ["store", "storeSub", "update", "updateSub"]),
    storeCategory() {
      this.store({
        ...this.dataCategory,
      })
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Cadastrado com sucesso!',
            icon: 'ThumbsUpIcon',
            text: 'Operação executada com sucesso.',
            variant: 'success'
          },
        })   
      })
      .finally(() =>{
        this.fetchCategories();
        this.$refs['modal-store'].hide()
      });
    },
    storeSubCategory() {
      this.storeSub({
        ...this.dataSubCategory,
      })
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Cadastrado com sucesso!',
            icon: 'ThumbsUpIcon',
            text: 'Operação executada com sucesso.',
            variant: 'success'
          },
        })   
      })
      .finally(() =>{
        this.fetchCategories();
        this.$refs['modal-store'].hide()
      });
    },
    updateCategory() { 
      this.update({
        ...this.updateCategories,
      })
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Atualizado com sucesso!',
            icon: 'Edit2Icon',
            text: 'Operação executada com sucesso.',
            variant: 'warning'
          },
        })   
      })
      .finally(() =>{
        this.fetchCategories();
        this.$refs['modal-update-category'].hide()
      });
    },
    updateSubCategory() { 
      this.updateSub({
        ...this.updateSubCategories,
      })
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Atualizado com sucesso!',
            icon: 'Edit2Icon',
            text: 'Operação executada com sucesso.',
            variant: 'warning'
          },
        })   
      })
      .finally(() =>{
        this.$refs['modal-update-subcategory'].hide()
      });
    },
    fetchCategories(paginated = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("categories/fetchCategories", {
          paginated: paginated,
          page: page,
          generic: this.search,
          perPage: this.perPage,
        })
        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    fetchDetails(id){
      let category_id = id;
      this.$store
        .dispatch("categories/fetchDetails", {
          category_id: category_id,
        })
        .catch((err) => {
          console.error(err)
        });
    },
    deleteCategory(id) { 
      this.isLoading = true;
      let data = id; 
      this.$store.dispatch("categories/delete", data)
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Excluído com sucesso!',
            icon: 'Trash2Icon',
            text: 'Operação executada com sucesso.',
            variant: 'danger'
          },
        })
        this.isLoading = false;
        this.DeleteStatus = null;
      })
      .catch(err => { 
        console.error(err); 
      })
      .finally(() =>{
        this.fetchCategories();
        this.$refs['modal-delete-category'].hide()
      });
    },
    deleteSubCategory() { 
      let data = this.id_delete; 
      this.$store.dispatch("categories/delete", data)
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Excluído com sucesso!',
            icon: 'Trash2Icon',
            text: 'Operação executada com sucesso.',
            variant: 'danger'
          },
        })   
      })
      .catch(err => { 
        console.error(err); 
      })
      .finally(() =>{
        this.$refs['modal-delete-subcategory'].hide()
      });
    },
    showDetail(id){
      this.categoriesModel = this.$store.getters['categories/getCategoryById'](id)
    },
    handlePageChange(value) {
      this.fetchCategories(true, value);
    },
    newDeleteStatus(value){
      this.DeleteStatus = value;
    },
    newNameCategory(value){
      this.updateCategories.name = value;
    },
    update_image(e) {
      var files = e.target.files;
      this.dataCategory.icon =  files[0]
    },
    newColorCategory(value){
      this.updateCategories.color = value;
    },
    update_image_update(e) {
      var files = e.target.files;
      this.updateCategories.icon =  files[0]
    },

  },
  created() {
    this.fetchCategories();
    this.fetchDetails();
    if (! this.csrf) {
      console.warn('The CSRF token is missing. Ensure that the HTML header includes the following: <meta name="csrf-token" content="{{ csrf_token() }}">');
    }
  },
  watch: {


  },
};
</script>